<template>
    <main-container>
        <header-container>
            <PackageDetailsHeader />
        </header-container>
        <div v-if="packageLoading == false" style="height: 94vh; max-height: 94vh; padding-bottom: 80px; overflow-y: scroll; scroll-behavior: smooth; background-color: white !important" id="scrollableDiv">
            <img :src="packageDetail.packageBanner" alt="" style="height: 150px; width: 100%; object-fit: cover">
            <div class="packageDetailContainer">
                <OPDPackageCommonPackageNameAndTestCount :showTestCount="false" :item="packageDetail" />
                <div style="display: flex; justify-content: space-between;">
                    <div class="aboutClass">
                        <div style="display: flex; align-items: center; gap: 8px;">
                            <span style="font-weight: 600;">{{$t("Customer.PackageAppointments.Package_start_date")}}</span>
                        </div>
                        <div >
                            <div style="font-size: 14px;">{{ formatDate(packageDetail.startDateNo) }}</div>
                        </div>
                    </div>
                    <div class="aboutClass">
                        <div style="display: flex; align-items: center; gap: 8px;">
                            <span style="font-weight: 600;">{{$t("Customer.PackageAppointments.packag_end_date")}}</span>
                        </div>
                        <div >
                            <div style="font-size: 14px;">{{ formatDate(packageDetail.endDateNo) }}</div>
                        </div>
                    </div>
                </div>
                <div class="aboutClass">
                    <span style="color: #2d264b; font-weight: 600; font-size: 1rem;">{{$t("Customer.OpdAppointments.About")}}</span>
                    <p v-if="isEngLanguage()">{{ packageDetail.descriptionEN }}</p>
                    <p v-else>{{ packageDetail.descriptionMM }}</p>

                </div>
                <div class="aboutClass">
                    <span style="color: #2d264b; font-weight: 600; font-size: 1rem;">{{$t("Customer.PackageAppointments.test_Included")}} ({{ packageDetail.testList.length }})</span>
                    <table class="testTable">
                        <tr class="tableHeader">
                            <th class="firstCol" style="font-size: 14px; font-weight: 500;">S.No.</th>
                            <th class="secondCol" style="font-size: 14px; font-weight: 500;">Test Details</th>
                        </tr>
                        <tr v-for="(testItem, index) in packageDetail.testList" :key="index">
                            <th class="firstCol" style="font-size: 14px; font-weight: 400; text-align: center;">{{ index + 1 }}</th>
                            <th class="secondCol" style="font-size: 14px; font-weight: 400;">{{ testItem }}</th>
                        </tr>
                    </table>
                </div>

                <div class="recommendationClass">
                    <div style="display: flex; align-items: center; gap: 8px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/cautionGolder.svg" alt="">
                        <span style="color: #c59348; font-weight: 600;">{{$t("Customer.PackageAppointments.recommendation")}}</span>
                    </div>
                    <div >
                        <ul style="display: flex; flex-direction: column; gap: 8px;">
                            <li v-for="(item, index) in packageDetail.packageRecommendation" :key="index" > {{ isEngLanguage() ? item.englishValue : item.burmeseValue }}</li>

                        
                        </ul>
                    </div>
                </div>
                <div class="slotClass" v-if="!packageDetail.sold_out">
                    <div style="display: flex; align-items: center; margin-bottom: 8px;">
                        <span style=" font-weight: 600;">{{$t("Customer.OpdAppointments.select_Date")}}</span>
                    </div>
                    <div style="width: 100%;" v-if="showSlot">
                        <div 
                        class="dateContainer"
                            >
                                <div :class="getClassForDateBox(slotItem.slotID, slotItem)" v-for="(slotItem) in packageDetail.slotList" :key="slotItem.slotID" @click="showSelectSlotFn(slotItem.slotID, slotItem)">
                                    <div v-if="slotItem.slotID == 'CALENDAR'" style="display: flex; align-items: center; justify-content: center;">
                                        <img :src="selectedID == 'CALENDAR' ? 'https://s3iconimages.mymedicine.com.mm/calendarBlue.svg': 'https://s3iconimages.mymedicine.com.mm/calendarGrey.svg'" alt="" style="width: 30px; height: 30px;">
                                    </div>
                                    <div>
                                        <div  class="weekDayClass" style="color: #828282; font-size: 14px;">{{ slotItem.dayValue }}</div>
                                        <div class="weekDayClass" style="font-weight: 600; font-size: 14px;">{{ slotItem.dateValue }}</div>
                                    </div>    
                                </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="bookPackage">
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <span>MMK {{ packageDetail.price }}</span>
                            <v-btn color="#48acef" style="color: white;" :disabled="true">  
                                {{$t("Customer.PackageAppointments.Sold_out")}}
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div v-if="showSlot && showSelectSlot" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
                    <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.Select_Slot")}}</div>
                    <div style="width: 100%;">
                        <div style="display: flex; flex-direction: row; width: 100%; flex-wrap: wrap; gap: 8px;" 
                            >
                            <!-- :disabled="!calculateDateTime(cutOffHours, selectedDateItem.slotID, timeID.startTime)" -->
                                <div :class="getClassForSlotBox(index)" v-for="(timeID, index) in packageDetail.slotPattern[selectedDateItem.dayValue].slotTiming" :key="index" @click="showAddPatientFn(index, timeID)" >
                                    <div class="weekDayClass">{{ timeID }}</div>
                                </div>
                        </div>
                    </div>
                </div>
                <div v-if="showSlot && showAddPatient" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
                    <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.Who_is_patient")}}?</div>
                    <div style=" gap: 8px; width: 100%; display: flex;  align-items: flex-start;  " class="prajers">
                        <v-select
                        @input="enableStateList"
                        :items="dependent_members"
                        outlined
                        style="height: 40px;"
                        dense
                        item-value="_id"
                        item-text="dependent_name"
                        v-model="dependentSelected"
                        >
                            <template v-slot:selection="data">
                                <span style="padding-left: 10px;">{{ data.item.dependent_name }}</span>
                            </template>
                        </v-select>
                        <div style="height: 100%;">
                            <v-btn depressed color="#e4f5ff" height="40" min-width="40" @click="addDependentModel = true">
                                <img src="https://s3iconimages.mymedicine.com.mm/pluseIcon.svg" alt="">
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div v-if="showSlot && showAddPatient && showStateList" style="display: flex; flex-direction: column; width: 100%; align-items: flex-start; gap: 8px;">
            <div style="color: #828282; font-size: 14px;">{{$t("Customer.OpdAppointments.State_Township")}}</div>
            <div style=" gap: 8px; width: 100%; display: flex;  align-items: flex-start;">
                <div class="inputBox">
                        <v-autocomplete
                            @input="stateSelectionChange"
                            :items="stateList"
                            :dense="true"
                            placeholder="Select state"
                            label=""
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="selectedState"
                        >
                        <template v-slot:selection="data">
                        <span style="padding-left: 10px;">{{ data.item.name }}</span>
                    </template>
                    </v-autocomplete>
                </div>
            </div>
        </div>
            </div>
            <v-bottom-sheet  :persistent="true" v-model="openCalender" max-width="425px" >
                <v-sheet
                class="text-center"
                height="450px"
                >
                <div class="viewDetailsBottomContainer">
                    <div>
                        <div class="viewHospitalDetailsBottomSheetHeading">
                            <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">{{$t("Customer.OpdAppointments.select_Date")}}</div>
                            <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="() => {openCalender = false;}" style="cursor: pointer; width: 25px;"/>
                        </div>
                    </div>
                    <div class="viewDetailsBody">
                        <v-date-picker v-model="datePickerModel" :no-title="true" :full-width="true" :allowed-dates="isDateAllowed"></v-date-picker>
                    </div>
                    <div class="bottomBar">
                        <div>{{$t("Customer.PackageAppointments.Clear")}}</div>
                        <v-btn color="#48acef" depressed style="color: white;" @click="getParticularDateData" :disabled="calendarLoader || datePickerModel == null" :loading="calendarLoader" >{{$t("Customer.CreatePassword.Continue")}}</v-btn>
                    </div>
                    
                </div>

            </v-sheet>
            </v-bottom-sheet>
        </div>
        <div v-else style="height: 94vh; display: flex; align-items: center; justify-content: center;">
            <img src="https://s3iconimages.mymedicine.com.mm/packageSearchGif.gif" alt="" style="width: 250px; height: auto;">

        </div>
        <div v-if="addDependentModel">
            <AddDependentComponent :modelValue="addDependentModel" @addDependentInList="addDependentInList" @toggleAddDependentDialog="toggleAddDependentDialog"/>
        </div>
        <ConfirmAndReview v-if="showConfirmReview" :packageDetail="packageDetail" :timeInfo="timeInfo" :dateNumber="dateNumber" :bookFor="dependentSelected" :township="selectedState"/>

    </main-container>
</template>

<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import MainContainer from '../../../templates/mainContainer.vue';
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import OPDPackageParticularHospitalHeader from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageParticularHospitalHeader.vue';
import HospitalSkeletonLoader from '../../OPDBookingPages/componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import OPDPackageSelectSpecialty from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageSelectSpecialty.vue';
import ViewParticularOPDPackage from '../componentsUsedInPages/OPDPackageViewHospitalPage/viewParticularOPDPackage.vue';
import OPDPackageCommonPackageNameAndTestCount from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageCommonPackageNameAndTestCount.vue';
import ConfirmAndReview from '../componentsUsedInPages/OPDPackageDetails/ConfirmAndReview.vue';
import PackageDetailsHeader from '../componentsUsedInPages/OPDPackageDetails/packageDetailsHeader.vue';
import AddDependentComponent from '../../OPDBookingPages/componentUsedInPage/commonAcrossPages/addDependentComponent.vue';



export default {
  components: {BodyContainer, HeaderContainer,  MainContainer, OPDPackageParticularHospitalHeader, HospitalSkeletonLoader, OPDPackageSelectSpecialty, ViewParticularOPDPackage, OPDPackageCommonPackageNameAndTestCount, ConfirmAndReview, PackageDetailsHeader, AddDependentComponent },
    name: 'OPDPackageDetails',
    data: function() {
        return {
            token: null,
            packageID: '',
            packageDetail: {},
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            showStateList: false,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            hospitalRegNumber: null,
            searchBoxDiv: null,
            showSlot: true,
            dependentSelected: null,
            slotInfo: [],
            selectedID: null,
            selectedDateItem: null,
            selectedSlotId: null,
            showAddPatient: false,
            showSelectSlot: false,
            openCalender: false,
            timeInfo: null,
            datePickerModel: null,
            allowedDatePattern: ['Mon', 'Wed', 'Thu'],
            cutOffHours: 0,
            showConfirmReview: false,
            dateNumber:'',
            addDependentModel: false,
            selectedState: null,
            packageLoading: false,

        };
    },
    computed: {
        showHospitalInfoLoader () {
            return this.$store.state.OPDPackageParticularHospitalState.fetchingHospitalInfo;        
        },
        showPackageCompLoader () {
            return this.$store.state.OPDPackageParticularHospitalState.fetchingData;        
        },
        getPackageList() {
            return this.$store.state.OPDPackageParticularHospitalState.shownPackageList || [];        
        },
        stateList () {
            return this.$store.state.locationState.showLocationList;
        },
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.packageID = this.$route.params.packageID
        this.getDependentData();
        this.getPackageDetail();
        // this.$store.dispatch('OPDPackageParticularHospitalState/loadHospitalInfo', {hospitalRegNumber: this.hospitalRegNumber});        
        // this.$store.dispatch('OPDPackageParticularHospitalState/loadPackageList', {hospitalRegNumber: this.hospitalRegNumber});  

        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'HBAViewParticularPackageDetails',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'HBAViewParticularPackageDetails',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        formatDate(inputDate) {
            inputDate = inputDate.toString();
            let year = inputDate.slice(0, 4);
            let month = inputDate.slice(4, 6);
            let day = inputDate.slice(6, 8);
            return `${year}/${month}/${day}`;
        },

        addDependentInList(item) {
            this.dependent_members.push(item);
            this.dependentSelected = item._id;
            this.enableStateList();
            this.addDependentModel = false;

            // this.showStateList = true;
            // this.selectedState = item.stateID;
        },
        toggleAddDependentDialog(value) {
            if(value) {
                this.addDependentModel = true;
            } else {
                this.addDependentModel = false;
            }
        },
        getParticularDateData() {
            try {
                this.calendarLoader = true;
                this.overlay = true;
                let dayValue = this.getDayOfWeek(this.datePickerModel);
                let splitedDate = this.datePickerModel.split('-');
                let dateInput = splitedDate[0] + splitedDate[1] + splitedDate[2];
                this.dateNumber = dateInput;
                this.selectedDateItem = {
                    slotID: 'CALENDAR',
                    dayValue
                }
                this.calendarDateNumber = dateInput;
                this.showSelectSlot = true;
                this.openCalender = false;
            } catch (error) {
                console.log(error);
            } finally {
                this.calendarLoader = false;
            }

        },  
        isEngLanguage() {
            try {
                return this.$i18n.locale=='en' ;
            } catch (error) {
                return true;
            }
        },
        async getPackageDetail() {
            try {
                this.packageLoading = true;
                let packageResponse = await axios_auth_instance_hba_customer.get(`/package/${this.packageID}`);
                let packageData = packageResponse.data.packageInfo;
                this.packageDetail =  {...packageData, packageID: this.packageID};
                this.cutOffHours = packageData.cutOffHours;
            } catch (error) {
                if (error.responseStatus == 401 || error.responseStatus == 403) {
                        localStorage.setItem('redirectionFlow', true);
                        localStorage.setItem('redirectionURL', this.$router.history.current.fullPath);
                        this.$router.push({
                            name: 'Login'
                        });
                    }
                console.log(error);
            } finally {
                this.packageLoading = false;
            }
        },
        enableStateList() {
            // this.showConfirmReview = true;
            this.showStateList = true;
            this.selectedState = null;
            this.showConfirmReview = false;
            let index = this.dependent_members.findIndex((item) => (item._id == this.dependentSelected))
            if(this.dependent_members[index].stateID) {
                this.selectedState = this.dependent_members[index].stateID;
                this.showConfirmReview = true;
            }
        },
        stateSelectionChange() {
            this.showConfirmReview = true;
        },
        calculateDateTime(hours, dateNumber, startTime) {
            const today = new Date();
            hours = Number(hours);
            today.setHours(today.getHours() + hours);
            const yyyy = today.getFullYear();
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const dd = String(today.getDate()).padStart(2, '0');
            const HH = String(today.getHours()).padStart(2, '0');
            const mmFormat = String(today.getMinutes()).padStart(2, '0');
            const dateObject = {
                dateValue: Number(`${yyyy}${mm}${dd}`),
                time: `${HH}:${mmFormat}`
            };
            // Check if dateNumber and startTime are greater than dateObject values
            const inputDateValue = Number(dateNumber);
            const inputTimeValue = Number(startTime.replace(':', ''));
            let allowed = inputDateValue > dateObject.dateValue ||
                    (inputDateValue === dateObject.dateValue && inputTimeValue > Number(dateObject.time.replace(':', '')));
                    console.log(allowed);
            return allowed;
        },
        showAddPatientFn(id, item) {
            console.log(this.selectedDateItem);
            if(this.calculateDateTime(this.cutOffHours, this.selectedDateItem.slotID == 'CALENDAR' ? this.calendarDateNumber : this.selectedDateItem.slotID, item.split('-')[0].trim()) == false) {
                alert('Booking not allowed');
                return;
            }

            // if any selected before
            this.selectedSlotId = null;
            this.timeInfo = null;
            this.showAddPatient = true;
            this.selectedSlotId = id;
            this.showStateList = false;
            this.timeInfo = item;


        },
        getDayOfWeek(dateString) {
            let dateObject = new Date(dateString);
            
            const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            
            let dayOfWeek = dateObject.getDay();
            
            return daysOfWeek[dayOfWeek];
        },
        convertDate(dateString) {
            let parts = dateString.split('-');
            let formattedDate = parts.join('');
            return formattedDate;
        },
        isDateAllowed(date) {
            console.log(date);
            this.getDayOfWeek(date);
            return this.packageDetail.slotPattern[this.getDayOfWeek(date)].isAvailable == 'AVAILABLE' && parseInt(this.convertDate(date))>= parseInt(this.packageDetail.startDateNo) && parseInt(this.convertDate(date))<= parseInt(this.packageDetail.endDateNo) && parseInt(this.convertDate(date))>= parseInt(this.packageDetail.slotList[0].slotID);
        },
        getClassForDateBox(index, slotItem) {
            // if(slotItem.slotID == 'CALENDAR' && index != this.selectedID) return "dateBox"

            if(slotItem.slotID != 'CALENDAR' && (this.packageDetail.slotPattern[slotItem.dayValue].isAvailable == 'UNAVAILABLE' || parseInt(this.packageDetail.startDateNo) > parseInt(slotItem.slotID) || parseInt(this.packageDetail.endDateNo) < parseInt(slotItem.slotID))) {
                return "dateBox disabledDateBox"
            }
            if(index == this.selectedID) {
                return "dateBox selectedDateBox"
            } else {
                return "dateBox"
            }
        },
        getClassForSlotBox(index) {
            if(index == this.selectedSlotId) {
                return "slotBox selectedDateBox"
            } else {
                return "slotBox"
            }
        },
        showSelectSlotFn(id, slotItem) {
            this.showConfirmReview = false;
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            this.showStateList = false;
            this.selectedState = null;  
            this.dependentSelected = null;
            this.dateNumber = '';
            this.timeInfo = '';
            if(id == 'CALENDAR') {
                this.selectedID = id;
                this.selectedDateItem = slotItem;
                this.openCalender = true;
                return;
            }
            if(this.packageDetail.slotPattern[slotItem.dayValue].isAvailable == 'UNAVAILABLE') return;
            this.selectedID = id;
            this.selectedDateItem = slotItem;
            this.showSelectSlot = true;
            this.dateNumber = this.selectedID;
        },
        async getDependentData() {
            this.currentCustomer = this.$cookies.get("customerToken");
                let customerData = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
            };
            try {
                let dependentResponse = await axios_auth_instance_hba_customer.get("/user/dependents");
                var dependent_list = [];
                var dependent_members = dependentResponse.data.dependentList;
                this.dependent_members = dependent_members;
            } catch (error) {
                console.log(error);
            } 
        },

        handler() {
            this.searchMeDiv.style.position = 'relative';
            this.searchMeDiv.style.top = 0;
            let rect = this.searchBoxDiv.getBoundingClientRect();
            let divTop = rect.top + window.scrollY;
            console.log(divTop)
            if(divTop < 10) {
            this.searchMeDiv.style.display = 'none';
            this.allDetailsDiv.style.display = 'flex'
            }


        },
        showSelectRegion() {
             this.scrollableDiv.removeEventListener('scroll', this.handler);
            this.searchMeDiv.style.display = 'block';
            this.searchMeDiv.style.position = 'sticky';

            this.allDetailsDiv.style.display = 'none'
            setTimeout(() => {
                this.scrollableDiv.addEventListener('scroll', this.handler);

            }, 1000)

        },
    },
};

</script>
<style  scoped>
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
    z-index: 10;
    /* display: none; */
}
.packageDetailContainer{
    padding: 24px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.dateContainer {
    display: grid;
    grid-template-columns: repeat(7, 0.5fr); /* 6 columns with equal width */
    gap: 6px; /* Gap between boxes */
    max-width: 475px;
  }
.aboutClass {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    text-align: justify;
}
.testTable {
    border: 1px solid #E8E8EB;
    border-radius: 8px;
    width: 100%;
    border-collapse: collapse;
}
.testTable tr, th {
    border: 1px solid #E8E8EB;
    border-radius: 8px;
}
.tableHeader {
    background: #F5F7FB;
    border-radius: 8px;


} 
.firstCol {
    padding: 8px 20px 8px 20px;
    width: 20%;
}
.secondCol {
    padding: 8px 32px 8px 32px;
}
.recommendationClass {
    background: #FFF9F0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    border-radius: 8px;
}

.recommendationClass li {
    color: #7c5c2c;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.dateBox {
    display: flex;
    /* padding: 4px 12px 4px 12px; */
    border: 1px solid #e0e0e0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    /* width: 52px; */
    /* height: 52px; */
}
.selectedDateBox {
    background: #E4F5FF;
    border: 1px solid #48ACEF !important;
}
.disabledDateBox {
    background: #FFF;
    border: 1px solid #f2f2f2 !important;
}
.selectedDateBox .weekDayClass {
    color: #48ACEF !important;
}
.disabledDateBox .weekDayClass {
    color: #e0e0e0 !important;
    font-weight: 800 !important;
}
.slotBox {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #828282;
    font-weight: 400;
    width: 100%;
}
.v-input >>> .v-input__slot {
    padding: 0 !important;
}
.v-input__slot >>> .v-text-field {
    display: none;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.viewHospitalDetailsBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.viewDetailsBottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.viewDetailsBody {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.bottomBar {
    border-top: 1px solid #e8e8eb;
    display: flex; justify-content: space-between; align-items: center; width: 100%;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px -4px 8px 0px #0000000A;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
.inputBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}
::v-deep .v-input{
    width: 100%;
}
::v-deep .v-text-field__details{
    display: none;
}
::v-deep .v-date-picker-table .v-btn.v-btn--active {
    background-color: #1467BF !important;
    /* color: #fff !important; */
}
.bookPackage {
    padding: 14px 16px 14px 16px;
    border-top: 1px solid #e8e8e8;
}
</style>