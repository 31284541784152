<template>
<div class="mainDialogContainer">
    <div style="margin: auto" v-if="paymentUnderProgress">
        <v-overlay style="z-index: 203; font-weight: 600; color: white; font-size: 1rem;">
            <div style="margin-bottom: 10px;">{{$t("Customer.PackageAppointments.Please_dont_reload_the_page_payment_under_progress")}}</div>
            <v-progress-circular
            indeterminate
            color="primary"
            ></v-progress-circular>
        </v-overlay>
    </div>
    <div class="confirmAndReviewButton">
        <v-btn color="#48acef" style="color: white; width: 100%;" @click="showConfirmReview=true">Confirm & Review</v-btn>
    </div>
    <v-bottom-sheet  :persistent="true" v-model="showConfirmReview" max-width="425px">
        <v-sheet
            height="610px"
        >
            <div>
                <div class="topBar"> 
                    <span>{{$t("Customer.OpdAppointments.Review_Details")}}</span>
                    <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg" alt="" @click="showConfirmReview = false">
                </div>
                <div class="showReview" v-if="showReview">
                    <div class="packageDetailContainer" >
                        <img :src="packageDetail.packageBanner" alt="" style="border-top-left-radius: inherit; border-top-right-radius: inherit; height: 150px; object-fit: cover; width: 100%;">
                        <div style="width: 100%; padding: 16px; padding-top: 0; gap: 16px; display: flex; flex-direction: column; ">
                            <OPDPackageNameAndTestDetails  :showApplicableFor="true" :showBookSlot="false" :showPrice="false" :item="packageDetail"/>
                            <div style="display: flex; flex-direction: column; gap: 4px; align-items: flex-start;">
                                <span style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.PackageAppointments.slot_time")}}: </span>
                                <div style="font-size: 14px; font-weight: 500; color: #333333;">{{ timeInfo }}</div>
                                
                            </div>
                            <div style="display: flex; flex-direction: column; gap: 4px; align-items: flex-start;">
                                <span style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.CartPage.Address")}}: </span>
                                <div class="hospitalNameContainer">
                                    <div class="hospitalLogo">
                                        <img :src="packageDetail.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: contain; border-radius: 8px;"/>
                                    </div>
                                    <div class="hospitalName">
                                        <div style="font-size: 14px; font-weight: 500; color: #333333;">{{ packageDetail.hospitalName }}</div>
                                        <div style="font-size: 12px; font-weight: 400; color: #4f4f4f;">{{ packageDetail.hospitalAddress }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="paymentDiv" v-else>
                    <div class="paymentGatewayContainer" v-if="miniApp == false && mobileBrowser == false && platform != 'AYA'">
                        <div class="selectPaymentBox">
                            {{$t("Customer.PackageAppointments.Select_payment")}}
                        </div>
                        <div class="gatewayBox">
                            <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                            <v-radio value="kbzPay">
                                <template v-slot:label>
                                <div style="display: flex; align-items: center; gap: 10px; margin-left: 10px;">
                                    <img src="https://s3iconimages.mymedicine.com.mm/KBZPay_icon.svg" alt="">
                                    <div>KBZ Pay</div>
                                </div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="selectedPaymentRadio === 'kbzPay'" style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                        <div>{{$t("Customer.PackageAppointments.pay_using")}}</div>
                            <div class="generateQRBOX" @click="() => {updatePaymentMethod('KBZQR')}">
                            <img src="https://s3iconimages.mymedicine.com.mm/kbzQR.svg" alt="" >
                            <div>{{$t("Customer.PackageAppointments.Generate_Qr")}}</div>
                            <img v-if="selectedPaymentMethod === 'KBZQR'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                            </div>
                        </div>
                        <div class="gatewayBox">
                            <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                            <v-radio value="ayaPay">
                                <template v-slot:label>
                                <div style="display: flex; align-items: center; gap: 10px; margin-left: 10px;">
                                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayLogo.svg" alt="" style="width: 32px; height: 32px;">
                                    <div>Aya Pay</div>
                                </div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="selectedPaymentRadio === 'ayaPay'"  style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                            <div>{{$t("Customer.PackageAppointments.pay_using")}}</div>
                            <div style="display: flex; gap: 10px; margin-bottom: 16px;">
                            <div class="generateQRBOX" @click="() => {updatePaymentMethod('AYAREQ')}">
                                <img src="https://s3iconimages.mymedicine.com.mm/ayaPayNoCircle.svg" alt="">
                                <div>{{$t("Customer.OpdAppointments.phone_number")}}</div>
                                <img v-if="selectedPaymentMethod === 'AYAREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                            </div>
                            <div class="generateQRBOX" @click="() => {updatePaymentMethod('AYAQR')}">
                                <img src="https://s3iconimages.mymedicine.com.mm/ayaPayQR.svg" alt="">
                                <div>{{$t("Customer.PackageAppointments.Generate_Qr")}}</div>
                                <img v-if="selectedPaymentMethod === 'AYAQR'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">

                            </div>
                            </div>
                            <div v-if="selectedPaymentRadio=='ayaPay' && selectedPaymentMethod == 'AYAREQ'" style="width: 90%; margin-right: 8px;">
                            <v-text-field
                                :placeholder="$t('Customer.OpdAppointments.phone_number')"
                                outlined
                                Dense
                                v-model="appPhoneNumber"
                                style="width: 100%;"
                                width="100%"
                            ></v-text-field>                
                    </div>
                        </div>
                    </div>
                    <div class="paymentGatewayContainer" v-if="miniApp == false && mobileBrowser == true && platform != 'AYA'">
                        <div class="selectPaymentBox">
                            {{$t("Customer.PackageAppointments.Select_payment")}}
                        </div>

                        <div class="gatewayBox">
                            <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                            <v-radio value="kbzPay">
                                <template v-slot:label>
                                <div style="display: flex; align-items: center; gap: 10px;">
                                    <img src="https://s3iconimages.mymedicine.com.mm/KBZPay_icon.svg" alt="">
                                    <div>KBZ Pay</div>
                                </div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="selectedPaymentRadio === 'kbzPay'" style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                            <div>{{$t("Customer.PackageAppointments.pay_using")}}</div>
                            <div class="generateQRBOX" @click="() => {updatePaymentMethod('KBZREQ')}">
                                <img src="https://s3iconimages.mymedicine.com.mm/kbzQR.svg" alt="">
                                <div>{{$t("Customer.PackageAppointments.Open_KBZ_Pay")}}</div>
                                <img v-if="selectedPaymentMethod === 'KBZREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                            </div>
                        </div>
                        <div class="gatewayBox">
                            <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                            <v-radio value="ayaPay">
                                <template v-slot:label>
                                <div style="display: flex; align-items: center; gap: 10px;">
                                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayLogo.svg" alt="" style="width: 32px; height: 32px;">
                                    <div>Aya Pay</div>
                                </div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="selectedPaymentRadio === 'ayaPay'"  style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                            <div>{{$t("Customer.PackageAppointments.pay_using")}}</div>
                            <div style="display: flex; gap: 10px; margin-bottom: 16px;">
                            <div class="generateQRBOX" @click="() => {updatePaymentMethod('AYAREQ')}">
                                <img src="https://s3iconimages.mymedicine.com.mm/ayaPayNoCircle.svg" alt="">
                                <div>{{$t("Customer.OpdAppointments.phone_number")}}</div>
                                <img v-if="selectedPaymentMethod === 'AYAREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                            </div>
                            </div>
                            <div v-if="selectedPaymentRadio=='ayaPay' && selectedPaymentMethod == 'AYAREQ'" style="width: 90%; margin: 8px;">
                            <v-text-field
                                :placeholder="$t('Customer.OpdAppointments.phone_number')"
                                outlined
                                Dense
                                style="width: 100%;"

                                v-model="appPhoneNumber"
                            ></v-text-field>                
                    </div>
                        </div>
                    </div>
                </div>
                <div class="bookPackage" v-if="showReview">
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                        <span>{{ packageDetail.price }} MMK</span>
                        <v-btn color="#48acef" style="color: white;" v-if="!miniApp && platform !== 'AYA'" @click="showPaymentScreen"> 
                            {{$t("Customer.PackageAppointments.Proceed")}}
                        </v-btn>
                        <v-btn v-else-if="platform == 'AYA'" color="#48acef" style="color: white;" @click="redirectToAyaPayment" :disabled="!appPhoneNumber"> 
                            {{$t("Customer.PackageAppointments.Pay_now")}}
                        </v-btn>                        
                        <v-btn v-else color="#48acef" style="color: white;" @click="redirectToPayment"> 
                            {{$t("Customer.PackageAppointments.Pay_now")}}
                        </v-btn>
                    </div>
                </div>
                <div class="bookPackage" v-else>
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                        <span>{{ packageDetail.price }} MMK</span>
                        <div style="display: flex; gap: 12px;">
                            <v-btn v-if="platform !='AYA'" color="#48acef" style="color: white;" @click="redirectToPayment" :disabled="(miniApp == false && (selectedPaymentRadio == '' || selectedPaymentMethod == '' || (selectedPaymentMethod == 'AYAREQ' && appPhoneNumber == '')))"> 
                                {{$t("Customer.PackageAppointments.Pay_now")}}
                            </v-btn>
                            <v-btn color="#f4f6fa" style="color: black;" @click="showReviewAgain"> 
                                {{$t("Customer.Appointments.back")}}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</div>
</template>

<script>
import { axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
import OPDPackageNameAndTestDetails from '../OPDPackageViewHospitalPage/OPDPackageNameAndTestDetails.vue';


export default {
  components: {OPDPackageNameAndTestDetails},
    name: 'ConfirmAndReviewOPDPackage',
    props: ['packageDetail', 'timeInfo', 'dateNumber', 'bookFor', 'township'],
    data: function() {
        return {
            showConfirmReview: false,
            showPaymentOption: false,
            miniApp: false,
            selectedPaymentRadio: '',
            selectedPaymentMethod: '',
            appPhoneNumber: '',
            mobileBrowser: false,
            isMobile: false,
            freeConsultation: false,
            showReview: true,
            paymentUnderProgress: false,
            bookingID: '',
            intervalValue: null,
            platform: '',
            ayaPhoneNumber: ''
        };
    },
    computed: {

    },
    async mounted() {

        var userAgent = navigator.userAgent;
        this.platform = localStorage.getItem('platform');
        this.ayaPhoneNumber = localStorage.getItem('ayaPhoneNumber');
        this.appPhoneNumber = this.ayaPhoneNumber;
        if (userAgent.includes("hwminiapp")) {
            this.miniApp = true;
        } else if(screen.width < 520) {
            this.mobileBrowser = true;
        }
        this.isMobile = this.$device.mobile;
        console.log('Mini App', this.miniApp);
        console.log('Mobile Browser', this.mobileBrowser);
        console.log('Screen Width', screen.width);
    },

    methods: {
        showPaymentScreen () {
            this.showReview = false;
            this.showPaymentOption = true;
        },
        showReviewAgain() {
            this.showReview = true;
            this.showPaymentOption = false;
        },
        updatePaymentMethod(value) {
            this.selectedPaymentMethod = value;
        },
        async redirectToPayment() {
            this.paymentUnderProgress = true;
            try {
                let booking_data = {
                    timeInfo: this.timeInfo,
                    dateNumber: this.dateNumber,
                    packageID: this.packageDetail.packageID,
                    bookFor: this.bookFor,
                    state_ID: String(this.township),
                    applicableFor: this.packageDetail.applicableFor || []
                };
                if (this.miniApp === false ) {
                    if(this.selectedPaymentRadio == 'kbzPay' && this.selectedPaymentMethod == 'KBZQR') {
                        if (this.freeConsultation == false) {
                            booking_data.tradeType = "PAY_BY_QRCODE";
                            booking_data.tradeMerchant = "KBZ"
                        }
                    } else if (this.selectedPaymentRadio == 'kbzPay' && this.selectedPaymentMethod == 'KBZREQ') {
                        if (this.freeConsultation == false) {
                            booking_data.tradeType = "PAY_BY_REQUEST";
                            booking_data.tradeMerchant = "KBZ"
                        }
                    } else if(this.selectedPaymentRadio === 'ayaPay' && this.selectedPaymentMethod == 'AYAQR') {
                        if (this.freeConsultation == false) {
                            booking_data.tradeType = "PAY_BY_QRCODE";
                            booking_data.tradeMerchant = "AYA"
                    }
                    } else if(this.selectedPaymentRadio === 'ayaPay' && this.selectedPaymentMethod == 'AYAREQ') {
                        if (this.freeConsultation == false) {
                            booking_data.tradeType = "PAY_BY_REQUEST";
                            booking_data.tradeMerchant = "AYA"
                            booking_data.appPhoneNumber = this.appPhoneNumber
                        }
                    }
                } else {
                    booking_data.tradeType = 'KBZMINIAPP';
                    booking_data.tradeMerchant = 'KBZ';
                }
                console.log(booking_data);
                let paymentResponse = await axios_auth_instance_hba_customer.post('/user/bookPackage', booking_data);
                console.log(paymentResponse);
                let bookingRes = paymentResponse.data.data;
                this.bookingID = bookingRes._id;
                let paymentData = paymentResponse.data.payment_data;
                if(bookingRes.payment_status == 'SUCCESS') {
                    alert('SUCCESS');
                } else if(bookingRes.payment_status == 'WAITING') {
                    if (paymentData.trade_merchant == 'KBZQR' || paymentData.trade_merchant == 'AYAQR') {
                        let qr_code = paymentData.qr_code;
                        this.$router.push({
                            name: "PackageQRCode",
                            params: {
                            booking_id: bookingRes._id,
                            qr_code: qr_code,
                            amount: this.packageDetail.price,
                            paymentGateway: paymentData.trade_merchant
                            },
                        });
                    } else if (paymentData.trade_merchant == 'KBZREQ') {
                        let pwa_signed_url = paymentData.PWA_signed_url;
                        window.location.replace(pwa_signed_url); 
                    } else if (paymentData.trade_merchant == 'AYAREQ') {
                        alert('We have sent a request to your AYA Pay app for payment')
                        this.intervalValue = setInterval(this.verifyOrder, 10000)
                    } else if (paymentData.trade_merchant == 'KBZMINIAPP') {
                        try {
                            window.xm.native('startPay', {
                                prepayId: paymentData.prepay_id,
                                orderInfo: paymentData.orderInfo,
                                sign: paymentData.sign,
                                signType: paymentData.sign_type,
                                tradeType: 'MINIAPP'
                            }).then(function(response){
                                //2 for android, 3 for IOS
                                if(response.resultCode == 2 || response.resultCode == 3)
                                {
                                this.restart_payment = true;
                                }
                            }.bind(this));
                            this.intervalValue = setInterval(this.verifyOrder, 10000)
                            
                        } catch(Exception) {
                        console.log("Exception in mini app startpay: ");
                        console.log(Exception);
                        localStorage.removeItem("BookingDetails");
                        }
                    }
                } else {
                    alert('Payment status is not success');
                }

            } catch (error) {
                console.log(error);
            }
        },
        async redirectToAyaPayment() {
            this.paymentUnderProgress = true;
            try {
                let booking_data = {
                    timeInfo: this.timeInfo,
                    dateNumber: this.dateNumber,
                    packageID: this.packageDetail.packageID,
                    bookFor: this.bookFor,
                    state_ID: String(this.township),
                    applicableFor: this.packageDetail.applicableFor || []
                };
                booking_data.tradeType = "PAY_BY_AYA_REQUEST";
                booking_data.tradeMerchant = "AYA"
                booking_data.appPhoneNumber = this.appPhoneNumber
                let paymentResponse = await axios_auth_instance_hba_customer.post('/user/bookPackage', booking_data);
                console.log(paymentResponse);
                let bookingRes = paymentResponse.data.data;
                this.bookingID = bookingRes._id;
                let paymentData = paymentResponse.data.payment_data;
                if(bookingRes.payment_status == 'SUCCESS') {
                    alert('SUCCESS');
                } else if(bookingRes.payment_status == 'WAITING') {
                    if (paymentData.trade_merchant == 'AYAREQ') {
                        alert('We have sent a request to your AYA Pay app for payment')
                        this.intervalValue = setInterval(this.verifyOrder, 10000)
                    }
                } else {
                    alert('Payment status is not success');
                }

            } catch (error) {
                console.log(error);
            }
        },
        async verifyOrder() {
            let appointmentRes =  await axios_auth_instance_hba_customer.get(`/user/verifyPackagePayment/${this.bookingID}`);
            let paymentStatus = appointmentRes.data.bookingInfo.paymentStatus
            if (paymentStatus == 'PAID') {
                clearInterval(this.intervalValue);
                this.$router.push({
                    name: 'hospitalPackageBookingSuccess',
                    params: {
                        bookingInfo: { ...appointmentRes.data.bookingInfo, bookingID: this.bookingID},
                    }
                });
            } else if(paymentStatus == 'WAITING') {
                return;
            } else {
                console.log('Something else');
            }
        }
    },
};

</script>
<style  scoped>
.mainDialogContainer {
    /* padding: 20px; */
}
.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8e8;
    position: sticky;
    width: 100%;
    max-width: 425px;
    top: 0;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.bookPackage {
    padding: 14px 16px 14px 16px;
    border-top: 1px solid #e8e8e8;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 425px;
}
.paymentGatewayContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 16px; */
  border: 1px solid #ededed;
  margin-top: 10px;
  border-radius: 8px;
}
.gatewayBox {
  padding: 8px 16px 8px 16px;
  margin: 8px;

  border: 1px solid #ededed;
  border-radius: 8px;
}
.selectPaymentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 16px;
  background: #f4f6fa;
}
.v-input {
  margin: 0;
}
.v-input >>> .v-messages {
  display: none;
}
.generateQRBOX {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e5e9;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 10px;
  cursor: pointer;
  
}
.paymentDiv {
    padding: 20px;
}
.confirmAndReviewButton {
    padding: 14px 16px 12px 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 425px;
    box-shadow: 0px -4px 8px 0px #0000000A;
    background: white;
    box-shadow: 0px 4px 4px 0px #00000040;

}
.packageDetailContainer {
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>